@import url(https://fonts.googleapis.com/css?family=Hind&display=swap);
*, ul{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.text{
  font-size: 16px;
  line-height: 1.6;
}

.subHeader {
  font-size: 25px;
  margin-bottom: 3%;
  padding-bottom: 3.5;
}

.siteMapLine {
  border-bottom: 1px solid white;
}

.addLine {
  border-right: 2px solid black;
}

.header {
  font-size: 55px;
}

.hindFont {
  font-family: 'Hind', sans-serif;
}

mark {
  background-color: #ceffc9;
}

li {
  list-style-type: none;
}

.messagesDash {
display: flex;
align-items: center;
padding: 1%;
margin-right: 2%;
margin-top: 2%;
border: 2px solid #c9c9c9;
border-radius: 10px;
font-family: 'Hind', sans-serif;
font-size: 13px;
}
a {
  color: #000000;
}
a:link {
  color: #000000;
}
/* visited link */
a:visited {
  color: #000000;
}
/* mouse over link */
a:hover {
  color: #499DFF;
}
/* selected link */
a:active {
  color: #000000;
}
.title-desktop {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title-mobile {
  display: none;
  width: 100%;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 650px){
  .title-desktop{
      display: none;
  }
  .title-mobile {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.6rem;
  }
  .mobile-button {
    width:100px;
    margin: 20px;
  }
}

/* mobile sidebar styling */
/* Individual item */
.bm-item {
  display: inline-block;
  /* sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 4%;
  top: 30%;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: black;
}

/* General sidebar styles */
.bm-menu {
  background: black;
  opacity: 90%;
  padding: 2.5em 1.5em 0;
  font-size: 20px;

  width: 70%;
  height: 375% !important;

  color: #b8b7ad;
  margin-right: 250% !important;

  overflow: auto;
}

/* Morph shape necessary with bubble or elastic */


/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}
.bm-overlay {
  opacity: 0% !important;
}
.desktop {
    background-image: url(/static/media/desktop.07d2eaf5.png);
    display: flex;
    flex: 1 1; 
    flex-direction: row;
    float: left;

    width: 100%;
    height: 100VH;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.desktopInfo{
    display: flex;
    flex-direction: row;
    width: 95%;
    height: 100VH;
    justify-content: center;
    align-items: center;
    opacity: 1.0;
}
.mobile{
    display:none;
} 
.mobInfo{
    display: none;
}
.title{
    color:white;
}

/* .welcome{
    background-image: url("../image/huddl.png");
    font-size: x-small;
} */
@media only screen and (max-width: 650px){
    .desktop{
        display:none;
    }
    .desktopInfo{
        display: none;
    }
    .mobInfo{
        display: flex;
        flex-direction: row;
        width: 95%;
        height: 100VH;
        justify-content: center;
        align-items: center;
        opacity: 1.0;
    }
    .mobile {
        /* flex-direction: row;
        /* width: 100%; */
        /* max-height: auto; */
        justify-content: center;
        align-items: center;
        line-height: 15%;
        background: url(/static/media/mobile2.b99c5dcd.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover; 
        height: 800px;
        /* overflow: hidden; */
        display: flex;
        flex: 1 1;
        /* margin: 0px; */
        float: left;
        flex-direction: column;
        padding-right: 8.4%;
        
    }
    .title{
        color: #6E6EFA;
        font-size: 1px;
        line-height: 90%;
        /* text-align: left; */
        padding-top: 10%;
        padding-bottom: 3%;
        padding-left: 5px;
        padding-right: 5px;
    }
}
a {
  color: #FFFFFF;
}

a:link {
  color: #666668;
}

/* visited link */
a:visited {
  color: #666668;
}

/* mouse over link */
a:hover {
  color: #1EA37D;
}

/* selected link */
a:active {
  color: #FFFFFF;
}

.contentWrapper{
  display: flex;
  flex-direction: row;
  flex-grow: 3;
}

@media only screen and (max-width: 790px){
  .contentWrapper{
    flex-direction: column;
  }
}


/*! react-sidenav v0.5.0 | (c) 2020 Trend Micro Inc. | MIT | https://github.com/trendmicro-frontend/react-sidenav */
.sidenav---sidenav---_2tBP {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1006;
  min-width: 64px;
  background: #6E6EFA;
  transition: min-width 0.2s;
}
.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv .sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ- .sidenav---sidenav-subnav---1EN61 {
  display: none;
}
.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv .sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---sidenav-subnav---1EN61 {
  display: block;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
  min-width: 240px;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- .sidenav---navicon---3gCRo + .sidenav---navtext---1AE_f {
  visibility: visible;
  opacity: 1;
  -ms-filter: none;
  -webkit-filter: none;
          filter: none;
  color: white;
  transition: opacity 0.2s linear;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---expandable---3_dr7 > .sidenav---navitem---9uL5T {
  cursor: pointer;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 {
  position: static;
  border: none;
  float: left;
  clear: both;
  width: 100%;
  background: transparent;
  overflow: hidden;
  box-shadow: none;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 {
  display: block;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 {
  display: none;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 {
  position: relative;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47::before {
  content: " ";
  width: 100%;
  height: 28px;
  position: absolute;
  top: 0;
  z-index: -1;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:first-child {
  display: none;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:hover::before {
  background: #fff;
  opacity: 0.15;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=15)";
  filter: alpha(opacity=15);
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47::before {
  content: " ";
  width: 100%;
  height: 28px;
  position: absolute;
  top: 0;
  z-index: -1;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y > .sidenav---navitem---9uL5T {
  color: #000;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y > .sidenav---navitem---9uL5T::before {
  content: " ";
  width: 2px;
  height: 20px;
  left: 10px;
  top: 4px;
  position: absolute;
  border-left: 2px #fff solid;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:hover > .sidenav---navitem---9uL5T {
  background: transparent;
  color: #fff;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T {
  color: #f9dcdd;
  padding: 0 14px 0 20px;
  line-height: 28px;
}
.sidenav---sidenav-toggle---1KRjR {
  position: relative;
  float: left;
  width: 64px;
  height: 64px;
  padding: 0;
  margin: 0;
  background-color: transparent;
  background-image: none;
  border: 0;
  border-radius: 0;
  cursor: pointer;
}
.sidenav---sidenav-toggle---1KRjR:focus {
  outline: none;
}
.sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
  display: block;
  width: 20px;
  height: 2px;
  margin: 0 auto;
  background-color: black;
  border-radius: 1px;
  transition: all 0.15s;
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
}
.sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 + .sidenav---icon-bar---u1f02 {
  margin-top: 4px;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
  width: 25px;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02:nth-child(1) {
  transform: rotate(45deg);
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02:nth-child(2) {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02:nth-child(3) {
  margin-top: -8px;
  transform: rotate(-45deg);
}

.sidenav---sidenav-nav---3tvij {
  float: left;
  padding: 0;
  margin: 0;
  clear: both;
  list-style: none;
  width: 100%;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- {
  clear: both;
  position: relative;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:nth-last-child(2){
  margin-top: 270px;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T {
  cursor: default;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T::after {
  background: #fff;
  opacity: 0.15;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=15)";
  filter: alpha(opacity=15);
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T::after,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T::after {
  background: #000;
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u.sidenav---expanded---1KdUL > .sidenav---navitem---9uL5T::after,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u.sidenav---expanded---1KdUL > .sidenav---navitem---9uL5T::after {
  background: #000;
  opacity: 0.25;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
  filter: alpha(opacity=25);
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u.sidenav---selected---1EK3y.sidenav---expanded---1KdUL > .sidenav---navitem---9uL5T::after,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u.sidenav---selected---1EK3y.sidenav---expanded---1KdUL > .sidenav---navitem---9uL5T::after {
  background: #000;
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
  opacity: 1;
  -ms-filter: none;
  -webkit-filter: none;
          filter: none;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
  color: white;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > *,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > *,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > *,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > * {
  color: white;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T {
  position: relative;
  display: block;
  line-height: 50px;
  height: 50px;
  white-space: nowrap;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T:focus {
  outline: 0;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T::after {
  content: '';
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  z-index: -1;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
  color: white;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > *,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > * {
  color:white ;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
  display: block;
  float: left;
  width: 64px;
  height: 50px;
  margin-right: -6px;
  vertical-align: top;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
  line-height: 50px;
  text-align: center;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo + .sidenav---navtext---1AE_f {
  width: 0;
  visibility: hidden;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  transition: visibility 0s 0.2s, opacity 0.2s linear;
}
.sidenav---sidenav-subnav---1EN61 {
  min-width: 200px;
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  top: 0;
  left: 64px;
  background: #fff;
  border: 1px #bbb solid;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.15);
}
.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:first-child {
  margin: 0 24px;
  line-height: 48px;
  border-bottom: 1px #ccc solid;
  font-size: 14px;
  font-weight: normal;
}
.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:nth-child(2) {
  margin-top: 8px;
}
.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:last-child {
  margin-bottom: 8px;
}

.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y > .sidenav---navitem---9uL5T {
  color: #000;
  font-weight: bold;
  background: none;
  cursor: default;
}
.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y > .sidenav---navitem---9uL5T:hover {
  background: #fff;
}
.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T {
  display: block;
  text-decoration: none;
  color:white;
  font-size: 13px;
  line-height: 30px;
  padding: 0 24px;
  cursor: pointer;
}
.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T:hover {
  background: #eee;
}
.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T:focus {
  outline: 0;
}

.sidenav---sidenav---_2tBP {
  box-sizing: border-box;
  line-height: 20px;
}
.sidenav---sidenav---_2tBP *,
.sidenav---sidenav---_2tBP *:before,
.sidenav---sidenav---_2tBP *:after {
  box-sizing: inherit;
}

/*# sourceMappingURL=react-sidenav.css.map*/
.middleDiv{
    display:flex;
    flex-flow: row wrap;
    align-items: flex-start;
    width: 100%;
}

.forumContainerOne{
    display:flex;
    flex-direction: column;
    width: 49%;
    margin-right: 2%;
    margin-bottom: 20px;
}

.forumContainerTwo{
    display:flex;
    flex-direction: column;
    width: 49%;
    margin-bottom: 20px;
}

@media only screen and (max-width: 650px){
    .middleDiv{
        flex-direction: column;
    }
    .forumContainerOne{
        width:100%;
        margin-right:0;
    }
    .forumContainerTwo{
        width:100%;
    }
}
.display-desktop {
    margin-left:10%;
}

.display-mobile{
    display:none;
}

.container{
    display: flex;
    flex-direction: row;
    margin-bottom: 5%;}

.titlee{
    width: 100%;
    font-size: 5vw;
    margin-top:5%;
}
.rightColumn{
    float:left;
    width: 44.66%;
    padding-top: 10%; 
    padding-left: 200;
    padding-right: 100;
    padding-bottom: 200;
}

.leftColumn{
    float:left;
    width: 55.33%;
    padding-top: 100;
    padding-left: 200;
    padding-right: 100;
    padding-bottom: 200;
    font-size:18px;
}

.image{
    margin-left:20%;
}

@media only screen and (max-width: 650px){
    .display-desktop{
        display:none;
    }
    .display-mobile {
        display:flex;
        flex-direction: column;
        margin-left: 5%;
        margin-right: 5%;
    }
    .title{
        font-size:10vw;
    }
    .container{
        display:flex;
        flex-direction: row;
    }
    .rightColumn{
        padding: 0px;
        margin-top: 5%;
    }
    .leftColumn{
        width: 100%;
        padding: 0px;
        margin-left: 5%;
        margin-bottom:5%;
        padding-right:10%;
        font-size: small;
    }
    .image{
        width: 60px;
    }
}
.display-desktop {
    margin-left:10%;
}

.display-mobile{
    display:none;
}

.container{
    display: flex;
    flex-direction: row;
    margin-bottom: 5%;
}

.titlee{
    width: 100%;
    font-size: 5vw;
    margin-top:5%;
}
.rightColumn{
    float:left;
    width: 44.66%;
    padding-top: 10%; 
    padding-left: 200;
    padding-right: 100;
    padding-bottom: 200;
}

.leftColumn{
    float:left;
    width: 55.33%;
    padding-top: 100;
    padding-left: 200;
    padding-right: 100;
    padding-bottom: 200;
    font-size: 18px;
}

.image{
    margin-left:10%;
}

@media only screen and (max-width: 650px){
    .display-desktop{
        display:none;
    }
    .display-mobile {
        display:flex;
        flex-direction: column;
        margin-left: 5%;
        margin-right: 5%;
    }
    .title{
        font-size:10vw;
    }
    .container{
        display:flex;
        flex-direction: row;
    }
    .rightColumn{
        padding: 0px;
        margin-top: 5%;
    }
    .leftColumn{
        width: 100%;
        padding: 0px;
        margin-left: 5%;
        margin-bottom:5%;
        padding-right:10%;
        font-size: small;
    }
    .image{
        width: 70px;
        padding-top: 4%;
    }
}
.display-desktop {
    margin-left:10%;
}

.display-mobile{
    display:none;
}

.container{
    display: flex;
    flex-direction: row;
    margin-bottom: 5%;
}

.titlee{
    width: 100%;
    font-size: 5vw;
    margin-top:5%;
}
.rightColumn{
    float:left;
    width: 44.66%;
    padding-top: 10%; 
    padding-left: 200;
    padding-right: 100;
    padding-bottom: 200;
}

.leftColumn{
    float:left;
    width: 55.33%;
    padding-top: 100;
    padding-left: 200;
    padding-right: 100;
    padding-bottom: 200;
    font-size:18px;
}

.image{
    margin-left:10%;
}

@media only screen and (max-width: 650px){
    .display-desktop{
        display:none;
    }
    .display-mobile {
        display:flex;
        flex-direction: column;
        margin-left: 5%;
        margin-right: 5%;
    }
    .title{
        font-size:6vw;
        width: 90%;
        padding-left: 2%;
    }
    .container{
        display:flex;
        flex-direction: row;
    }
    .rightColumn{
        padding: 0px;
        margin-top: 5%;
    }
    .leftColumn{
        width: 100%;
        padding: 0px;
        margin-left: 5%;
        margin-bottom:5%;
        padding-right:10%;
        font-size: small;
    }
    .image{
        width: 70px;
        padding-top: 16%;
        float: right;
    }
}
h1 {
    font-size: 350%;
    position: absolute;
    margin-left: 15%;
    margin-top: 2%;

}
body {
    font-family: 'Hind', sans-serif;
}
ul {
    list-style-type: none;
}
a {
    text-decoration: none;
}
.tags {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: #373a47;

    border-radius: 5%;
    width: 10%;
    height: 170px;
    text-align: left;
    padding:2%;

    position: absolute;
    margin-left: 15%;
    margin-top: 20%;

}
.feed {

    width: 50%;
    height: 90%;
    overflow-y: scroll;

    position: absolute;
    margin-left: 30%;
    margin-top: 6%;


}
.feed-item {
    width: 95%;
    height: 85%;
    border-radius: 3%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #F5F5F5;
    margin-bottom: 10%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
}
.item-img {
    height: 50%;
    border-radius: 3%;
    padding: 3%;
}
.img {
    border-radius: 3%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.item-text {
    padding: 5%;
}
.item-author {
    font-size: 120%;
    color: gray;
    position: relative;
    float: left;
}
.hrs {
    font-size: 120%;
    color: gray;
    position: relative;
    float: right;
}
.item-header {
    position: absolute;
    margin-top: 5%;
    font-size: 200%;
}
.item-desc {
    position: absolute;
    margin-top: 20%;
}

@media only screen and (max-width: 650px) {

    h1 {
        margin-left: 20%;
        font-size: 200%;
    }
    .tags {
        font-size: 40%;
        margin-left: 20%;
        width: 15%;
        height: 13%;
    }

    .feed {
        margin-left: 36%;
        margin-top: 16%;
        width: 60%;
        padding: 1%;
    }
    .feed-item {
        height: 40%;
 
    }

    .item-img {
        height: 50%;
        border-radius: 3%;
        padding: 3%;
    }
    .img {
        border-radius: 3%;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .item-text {
        margin:auto;
    }
    .item-author {
        font-size: 50%;
        color: gray;
        position: relative;
        float: left;
    }
    .hrs {
        font-size: 50%;
        color: gray;
        position: relative;
        float: right;
    }
    .item-header {
        position: absolute;
        margin-top: 6%;
        font-size: 80%;
    }
    .item-desc {
        position: absolute;
        margin-top: 30%;
        font-size: 30%;
    }
    
                
}
h1 {
    font-size: 150%;
    position: absolute;
    margin-left: 10%;
    margin-top: -5%;
    color: gray;

}
body {
    font-family: 'Hind', sans-serif;
}

.feed-item {
    box-shadow: none !important;
    background-color: white;
    margin-left: 10%;
    width: 80%;
    margin-top: 7%;
    height: 100%;
}

.img {
    box-shadow: none !important;
    border: none;
    height: 300px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.item-text {
    padding: 5%;
}
.item-author {
    margin-top: -5%;
    font-size: 150%;
    color: gray;
    position: relative;
    float: left;
}
.hrs {
    margin-top: -5%;
    font-size: 150%;
    color: gray;
    position: relative;
    float: right;
}
.item-header {
    position: relative;
    font-size: 200%;
    margin-top: 0%;
}
.item-desc {
    font-size: 120%;
    margin-top: 2%;
    position: relative;
}

@media only screen and (max-width: 600px) {

    h1 {
        margin-left: 20%;
        font-size: 100%;
        margin-top: -10%;
    }
    .feed-item {
        margin-left: 25%;
        width: 70%;
        margin-top: 15%;
        height: 100%;
    }
    .img {
        height: 300px;
        width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .item-text {
        margin:auto;
    }
    .item-author {
        font-size: 100%;
        color: gray;
        position: relative;
        float: left;
    }
    .hrs {
        font-size: 100%;
        color: gray;
        position: relative;
        float: right;
    }
    .item-header {
        position: relative;
        margin-top: 6%;
        font-size: 150%;
    }
    .item-desc {
        position: relative;
        font-size: 80%;
    }
    
                
}
body {
  background: #F7F7FF;
  color: #6E6EFA;
  display:flex;
  flex-direction: column;
}

h1 {
  font-size: 7vw;
  font-family: 'Hind', sans-serif;
  text-align: left;
  margin-bottom:0px;
  margin-top:0px;
  margin-left: 40px;
  color: black;
  /* border-bottom: 1px solid rgb(79, 98, 148); */
}

.sidebar{
  /* position:fixed; */
}

.title{
  margin-left:5%;
  margin-top: 4%;
}

.submitButton{
 width: 100%;
 margin-left:3%;
}

.overall{
  display:flex;
  flex-flow: row wrap;
  align-items: flex-start;
  width:100%;
  margin-left: 5%;
}

.leftHalf {
  width:45%;
  margin-top: 9%;
}

.rightHalf {
  width:45%;
  margin-top: 10%
}

@media only screen and (max-width: 790px){
  h1{
    font-size: 8vw;
  }
  .overall{
      flex-direction: column;
      width: 100%;
  }
  .leftHalf{
      width:90%;
      margin-left:0;
  }
  .rightHalf{
    margin-top:2%;
    width:98%;
  }
  .submitButton{
    margin-left:10%;
  }
}

.form{
  margin-left:50px;
}

.notes{
  margin-left:50px;
  margin-right:50px;
}

.input_label{
  color: #6E6EFA !important;
  border-color: #6E6EFA !important;
}


.datepicker{
  font-family: 'Hind', sans-serif;
  font-size: 16px;
  line-height: 140%;
  border: #6E6EFA;
  border-style: solid;
  border-width: thin;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
}

h5{
  margin-top: 10px;
  margin-bottom: 10px;
}

.calendarRepeat{
  margin-top: 10px;
  width: 50%;
}

.submitButton {
  margin-top: 10px;
}
