.display-desktop {
    margin-left:10%;
}

.display-mobile{
    display:none;
}

.container{
    display: flex;
    flex-direction: row;
    margin-bottom: 5%;}

.titlee{
    width: 100%;
    font-size: 5vw;
    margin-top:5%;
}
.rightColumn{
    float:left;
    width: 44.66%;
    padding-top: 10%; 
    padding-left: 200;
    padding-right: 100;
    padding-bottom: 200;
}

.leftColumn{
    float:left;
    width: 55.33%;
    padding-top: 100;
    padding-left: 200;
    padding-right: 100;
    padding-bottom: 200;
    font-size:18px;
}

.image{
    margin-left:20%;
}

@media only screen and (max-width: 650px){
    .display-desktop{
        display:none;
    }
    .display-mobile {
        display:flex;
        flex-direction: column;
        margin-left: 5%;
        margin-right: 5%;
    }
    .title{
        font-size:10vw;
    }
    .container{
        display:flex;
        flex-direction: row;
    }
    .rightColumn{
        padding: 0px;
        margin-top: 5%;
    }
    .leftColumn{
        width: 100%;
        padding: 0px;
        margin-left: 5%;
        margin-bottom:5%;
        padding-right:10%;
        font-size: small;
    }
    .image{
        width: 60px;
    }
}