.desktop {
    background-image: url("../image/desktop.png");
    display: flex;
    flex: 1; 
    flex-direction: row;
    float: left;

    width: 100%;
    height: 100VH;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.desktopInfo{
    display: flex;
    flex-direction: row;
    width: 95%;
    height: 100VH;
    justify-content: center;
    align-items: center;
    opacity: 1.0;
}
.mobile{
    display:none;
} 
.mobInfo{
    display: none;
}
.title{
    color:white;
}

/* .welcome{
    background-image: url("../image/huddl.png");
    font-size: x-small;
} */
@media only screen and (max-width: 650px){
    .desktop{
        display:none;
    }
    .desktopInfo{
        display: none;
    }
    .mobInfo{
        display: flex;
        flex-direction: row;
        width: 95%;
        height: 100VH;
        justify-content: center;
        align-items: center;
        opacity: 1.0;
    }
    .mobile {
        /* flex-direction: row;
        /* width: 100%; */
        /* max-height: auto; */
        justify-content: center;
        align-items: center;
        line-height: 15%;
        background: url("../image/mobile2.png") ;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover; 
        height: 800px;
        /* overflow: hidden; */
        display: flex;
        flex: 1;
        /* margin: 0px; */
        float: left;
        flex-direction: column;
        padding-right: 8.4%;
        
    }
    .title{
        color: #6E6EFA;
        font-size: 1px;
        line-height: 90%;
        /* text-align: left; */
        padding-top: 10%;
        padding-bottom: 3%;
        padding-left: 5px;
        padding-right: 5px;
    }
}