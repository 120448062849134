.middleDiv{
    display:flex;
    flex-flow: row wrap;
    align-items: flex-start;
    width: 100%;
}

.forumContainerOne{
    display:flex;
    flex-direction: column;
    width: 49%;
    margin-right: 2%;
    margin-bottom: 20px;
}

.forumContainerTwo{
    display:flex;
    flex-direction: column;
    width: 49%;
    margin-bottom: 20px;
}

@media only screen and (max-width: 650px){
    .middleDiv{
        flex-direction: column;
    }
    .forumContainerOne{
        width:100%;
        margin-right:0;
    }
    .forumContainerTwo{
        width:100%;
    }
}