a {
  color: #FFFFFF;
}

a:link {
  color: #666668;
}

/* visited link */
a:visited {
  color: #666668;
}

/* mouse over link */
a:hover {
  color: #1EA37D;
}

/* selected link */
a:active {
  color: #FFFFFF;
}

.contentWrapper{
  display: flex;
  flex-direction: row;
  flex-grow: 3;
}

@media only screen and (max-width: 790px){
  .contentWrapper{
    flex-direction: column;
  }
}

