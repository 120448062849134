body {
  background: #F7F7FF;
  color: #6E6EFA;
  display:flex;
  flex-direction: column;
}

h1 {
  font-size: 7vw;
  font-family: 'Hind', sans-serif;
  text-align: left;
  margin-bottom:0px;
  margin-top:0px;
  margin-left: 40px;
  color: black;
  /* border-bottom: 1px solid rgb(79, 98, 148); */
}

.sidebar{
  /* position:fixed; */
}

.title{
  margin-left:5%;
  margin-top: 4%;
}

.submitButton{
 width: 100%;
 margin-left:3%;
}

.overall{
  display:flex;
  flex-flow: row wrap;
  align-items: flex-start;
  width:100%;
  margin-left: 5%;
}

.leftHalf {
  width:45%;
  margin-top: 9%;
}

.rightHalf {
  width:45%;
  margin-top: 10%
}

@media only screen and (max-width: 790px){
  h1{
    font-size: 8vw;
  }
  .overall{
      flex-direction: column;
      width: 100%;
  }
  .leftHalf{
      width:90%;
      margin-left:0;
  }
  .rightHalf{
    margin-top:2%;
    width:98%;
  }
  .submitButton{
    margin-left:10%;
  }
}

.form{
  margin-left:50px;
}

.notes{
  margin-left:50px;
  margin-right:50px;
}

.input_label{
  color: #6E6EFA !important;
  border-color: #6E6EFA !important;
}


.datepicker{
  font-family: 'Hind', sans-serif;
  font-size: 16px;
  line-height: 140%;
  border: #6E6EFA;
  border-style: solid;
  border-width: thin;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
}

h5{
  margin-top: 10px;
  margin-bottom: 10px;
}

.calendarRepeat{
  margin-top: 10px;
  width: 50%;
}

.submitButton {
  margin-top: 10px;
}