*, ul{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@import url('https://fonts.googleapis.com/css?family=Hind&display=swap');

html, body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.text{
  font-size: 16px;
  line-height: 1.6;
}

.subHeader {
  font-size: 25px;
  margin-bottom: 3%;
  padding-bottom: 3.5;
}

.siteMapLine {
  border-bottom: 1px solid white;
}

.addLine {
  border-right: 2px solid black;
}

.header {
  font-size: 55px;
}

.hindFont {
  font-family: 'Hind', sans-serif;
}

mark {
  background-color: #ceffc9;
}

li {
  list-style-type: none;
}

.messagesDash {
display: flex;
align-items: center;
padding: 1%;
margin-right: 2%;
margin-top: 2%;
border: 2px solid #c9c9c9;
border-radius: 10px;
font-family: 'Hind', sans-serif;
font-size: 13px;
}