a {
  color: #000000;
}
a:link {
  color: #000000;
}
/* visited link */
a:visited {
  color: #000000;
}
/* mouse over link */
a:hover {
  color: #499DFF;
}
/* selected link */
a:active {
  color: #000000;
}
.title-desktop {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title-mobile {
  display: none;
  width: 100%;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 650px){
  .title-desktop{
      display: none;
  }
  .title-mobile {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.6rem;
  }
  .mobile-button {
    width:100px;
    margin: 20px;
  }
}

/* mobile sidebar styling */
/* Individual item */
.bm-item {
  display: inline-block;
  /* sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 4%;
  top: 30%;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: black;
}

/* General sidebar styles */
.bm-menu {
  background: black;
  opacity: 90%;
  padding: 2.5em 1.5em 0;
  font-size: 20px;

  width: 70%;
  height: 375% !important;

  color: #b8b7ad;
  margin-right: 250% !important;

  overflow: auto;
}

/* Morph shape necessary with bubble or elastic */


/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}
.bm-overlay {
  opacity: 0% !important;
}